


















































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import axios from "axios";

import { api as appApi } from "@/store/modules/app";
import { ROUTE_NAMES } from "@/routenames";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";
import { userEndpointUrl } from "@/utils/HttpApi";

@Component({
  components: {},
})
export default class AUserManagement extends Vue {
  syncResult = "";
  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  get currentBreadCrumb(): any[] {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.breadcrumb"),
        to: { name: ROUTE_NAMES.USER_MANAGEMENT },
        activeclass: "",
      },
    ];
  }

  mounted() {
    this.setBreadCrumb(this.currentBreadCrumb);
    this.setMenuPanel();
  }

  openUsers() {
    this.$router.push({
      name: ROUTE_NAMES.USER_MANAGEMENT_USERS,
    });
  }

  openPersons() {
    this.$router.push({
      name: ROUTE_NAMES.USER_MANAGEMENT_PERSONS,
    });
  }
  openRoles() {
    this.$router.push({
      name: ROUTE_NAMES.USER_MANAGEMENT_ROLES,
    });
  }
  openGroups() {
    this.$router.push({
      name: ROUTE_NAMES.USER_MANAGEMENT_GROUPS,
    });
  }

  /*routeToUserCreation() {
    this.$router.push({ name: ROUTE_NAMES.USER_MANAGEMENT_CREATE_USERS });
  }*/

  startSync() {
    this.syncResult = "";
    console.log("startSync:started");
    const url = userEndpointUrl("sync");
    axios
      .get(url)
      .then(res => {
        console.log("startSync:done", res);
        this.syncResult = JSON.stringify(res.data, undefined, 2);
      })
      .catch(err => {
        console.log("startSync:failed", err);
        if (err.isAxiosError === true) {
          this.syncResult = `Error: ${err.code}
${err.response}`;
        } else {
          this.syncResult = "ERROR ..." + err;
        }
      });
  }
}
